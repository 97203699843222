import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"




const IndexPage = () => (
  <Layout>
    <SEO
      title="Courses, Blog, & More"
      description="If this is your first time here, this site hosts all of my writing on software engineering, entrepreneurship, and finance. I also teach people how to code through premium software training courses."
      />
    <h1 id="hithere">Hi, I'm Nick</h1>

    <p>This site hosts all of my writing about software engineering, entrepreneurship, business, and finance.</p>

    <p>I also teach people how to code through my premium Python and JavaScript training courses:</p>

    <ul>
      <li><a href="https://courses.nickmccullum.com/courses/enroll/python-fundamentals/">Python Fundamentals</a></li>
      <li><a href="https://courses.nickmccullum.com/courses/enroll/python-for-finance/">Python for Finance & Data Science</a></li>
      <li><a href="https://www.youtube.com/watch?v=xfzGZB4HhEE">Algorithmic Trading Using Python [FREE]</a></li>
    </ul>

    <p>Finally, if you're a growth-minded developer who wants to be constantly improving your skill set, you should join my newsletter. It's a periodic email that shares what I've been working on lately, and anything I've found recently:</p>
    <form
      action="https://buttondown.email/api/emails/embed-subscribe/nick-mccullum"
      method="post"
      target="popupwindow"
      className="embeddable-buttondown-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        maxWidth: '400px',
        margin: '0 auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif'
      }}
    >
      <label htmlFor="email" style={{ fontSize: '16px', marginBottom: '10px' }}>
        Email
      </label>
      <input
        type="email"
        name="email"
        placeholder="you@example.com"
        style={{
          width: '100%',
          padding: '10px',
          marginBottom: '20px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '14px'
        }}
      />
      <input type="hidden" value="1" name="embed" />
      <input
        type="submit"
        value="Subscribe"
        style={{
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '16px'
        }}
      />
      {/* <p style={{ marginTop: '20px', fontSize: '12px' }}>
        <a
          href="https://buttondown.email"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007bff',
            textDecoration: 'none'
          }}
        >
          Powered by Buttondown.
        </a>
      </p> */}
    </form>


    <p>Stay in touch,</p>

    <p>Nick</p>
  </Layout>
)

export default IndexPage
